<script setup lang="ts">
defineProps<{
  classes?: string
  showClose?: boolean
}>()

const show = ref(true)
</script>

<template>
  <div v-if="show" class="callout-container">
    <div :class="classes" class="callout">
      <slot />
      <button
        v-if="showClose"
        @click="show = false"
        class="icon close-button"
      >
        <Icon name="close" size="20px" />
      </button>
    </div> 
  </div>
</template>

<style scoped lang="scss">
.callout-container {
  display: flex;
  justify-content: center;
}
</style>
